import React, { useState, useEffect } from "react";
import { Modal, Fade } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "../../config";
import { Tabs, Tab, Typography, Box } from "@mui/material";
import PropTypes from "prop-types";
import Pesaje from "../modificacion/pesaje";
import Errores from "../modificacion/errores";
import Piezas from "../modificacion/piezas";
import Pruebas from "../modificacion/pruebas";
import { getReparacion } from "../../services/reparacion";
import Works from "../modificacion/trabajos";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    margin: "10px auto",
    height: "auto",
    maxHeight: "580px",
    overflowY: "scroll",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #2a8daa",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 0),
    borderRadius: "10px",
    "&:focus": {
      outline: "none",
    },
    width: "80%",
    overflowY: "scroll",
  },
  line: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
    color: "grey",
  },
  table: {
    display: "flex",
    justifyContent: "center",
    minHeight: "100px",
    padding: "10px",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ModalFormDetalle = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [reparacion, setReparacion] = useState();
  const [tipoProducto, setTipo] = useState("");

  useEffect(() => {
    const asyncFunc = async () => {
      const repar = await getReparacion(props.ticket.CaseNumber);
      if (repar.datos) {
        setReparacion(repar);
        setTipo(repar.datos.tipoProducto);
      } else setReparacion(null);
    };
    asyncFunc();
  }, [props.ticket.CaseNumber]);

  const handleChangeSelect = (event, value) => {
    setTipo(value?.id ?? null);
  };

  const handleClose = () => {
    props.close();
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Modal
      aria-labelledby="title"
      aria-describedby="description"
      open={props.state}
      className={classes.modal}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={props.state}>
        <div className={classes.paper}>
          <div className={classes.header}>
            <h3 id="title">Detalle del ticket: {props.ticket.CaseNumber}</h3>
          </div>
          <Box>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                aria-label="basic tabs example"
                sx={{
                  "& button.Mui-selected": { color: "rgb(17, 120, 100)" },
                }}
                TabIndicatorProps={{
                  sx: { backgroundColor: "rgb(17, 120, 100)" },
                }}
              >
                <Tab label="PESAJE Y MEDIDAS" {...a11yProps(0)} />
                <Tab label="DEFECTOS" {...a11yProps(1)} />
                <Tab label="REPARACIÓN" {...a11yProps(2)} />
                <Tab label="PIEZAS UTILIZADAS" {...a11yProps(3)} />
                <Tab label="TRABAJOS REALIZADOS" {...a11yProps(4)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Pesaje
                ticket={props.ticket}
                reparacion={reparacion}
                tipoProducto={tipoProducto}
                handleChange={handleChangeSelect}
                actualizar={(rep, type) => {
                  setReparacion(rep);
                  setTipo(type.id);
                }}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Errores
                ticket={props.ticket.CaseNumber}
                reparacion={reparacion}
                tipoProducto={tipoProducto}
                actualizar={setReparacion}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Pruebas
                ticket={props.ticket.CaseNumber}
                reparacion={reparacion}
                tipoProducto={tipoProducto}
                actualizar={setReparacion}
              />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Piezas
                ticket={props.ticket.CaseNumber}
                reparacion={reparacion}
                tipoProducto={tipoProducto}
                actualizar={setReparacion}
              />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <Works
                ticket={props.ticket.CaseNumber}
                idSalesforce={props.ticket.Id}
                intern={props.ticket.CB_Comentario_Interno__c}
                reparacion={reparacion}
                actualizar={setReparacion}
              />
            </TabPanel>
          </Box>
        </div>
      </Fade>
    </Modal>
  );
};

export default ModalFormDetalle;
