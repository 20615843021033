import React from 'react';
import { ListItem, ListItemText, ListItemAvatar, Avatar } from '@material-ui/core';

const ListItems = (props) => {

    const colitem = props.item.Id === props.eleg ? "rgba(42,141,170,0.1)" : "#ffffff"

    const handleClick = (event) => {
        props.onChange(props.item.Id)
    }

    return (

        <ListItem alignItems="flex-start" style={{background: colitem, cursor: "pointer"}}  onClick={handleClick}>
            <ListItemAvatar>
                <Avatar alt="Ticket" src="/static/images/avatar/1.jpg" />
            </ListItemAvatar>
            <ListItemText
                primary={props.item.CaseNumber} 
                secondary={props.item.SuppliedName ? props.item.SuppliedName.toString().substring(0, 25) : ""}
            />
        </ListItem>
    )
}

export default ListItems;