import { Modal, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import "../../config";
import Camera, { FACING_MODES } from "react-html5-camera-photo";
import {
  addImagen,
  updateLineas,
  verificarTicketInterno,
} from "../../services/tickets";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import "react-html5-camera-photo/build/css/index.css";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    justifyContent: "center",
    marginTop: "40px",
  },
  paper: {
    overflowY: "auto",
    width: "90%",
    maxHeight: "80%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #2a8daa",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "10px",
    "&:focus": {
      outline: "none",
    },
  },
}));

const ModalVerificar = (props) => {
  const [foto, setFoto] = useState("");
  const classes = useStyles();
  const [comentarioAlmacen, setComentarioAlmacen] = useState(
    props.ticket.CB_Comentario_Almacen__c
  );
  const [lineas, setLineas] = useState(
    props.lineas.map((linea) => {
      return {
        ...linea,
        CB_Piezas_no_aptas_recibidas__c:
          linea.CB_Piezas_no_aptas_recibidas__c ?? linea.CB_Piezas_no_aptas__c,
        CB_Piezas_aptas_recibidas__c:
          linea.CB_Piezas_aptas_recibidas__c ?? linea.CB_Piezas_aptas__c,
        CB_Cantidad_revisada__c:
          linea.CB_Cantidad_revisada__c ?? linea.CB_cantidad__c,
      };
    })
  );

  const handleVerificar = async () => {
    const res = await verificarTicketInterno({
      id: props.ticket.CB_Ticket_Interno__c,
      comentario: comentarioAlmacen,
    });
    var resp = null;
    for(let linea of lineas){
      resp = await updateLineas({
        id:linea.Id,
        CB_Piezas_no_aptas_recibidas__c: linea.CB_Piezas_no_aptas_recibidas__c,
        CB_Piezas_aptas_recibidas__c: linea.CB_Piezas_aptas_recibidas__c,
        CB_Cantidad_revisada__c: linea.CB_Cantidad_revisada__c,
      });
    }
    if (res?.status === 200 && resp?.status) {
      NotificationManager.success("Ticket verificado con éxito.");
      props.close();
    } else {
      NotificationManager.error("¡ERROR! Inténtalo más tarde.");
    }
  };

  const handleClose = () => {
    setComentarioAlmacen(null);
    props.close();
  };

  const handleAdjuntar = async () => {
    const res = await addImagen(
      Date.now(),
      foto,
      props.ticket.CB_Ticket_Interno__c
    );
    if (res?.status === 200) {
      NotificationManager.success("Imagen adjuntada con éxito.");
      setFoto("");
    } else {
      NotificationManager.error(
        "¡ERROR adjuntando imagen! Inténtalo más tarde."
      );
    }
  };

  useEffect(() => {
    setComentarioAlmacen(props.ticket.CB_Comentario_Almacen__c);
    setLineas(
      props.lineas?.map((linea) => {
        return {
          ...linea,
          CB_Piezas_no_aptas_recibidas__c:
            linea.CB_Piezas_no_aptas_recibidas__c ??
            linea.CB_Piezas_no_aptas__c,
          CB_Piezas_aptas_recibidas__c:
            linea.CB_Piezas_aptas_recibidas__c ?? linea.CB_Piezas_aptas__c,
          CB_Cantidad_revisada__c:
            linea.CB_Cantidad_revisada__c ?? linea.CB_cantidad__c,
        };
      })
    );
  }, [props.lineas, props.ticket]);

  return (
    <Modal
      aria-labelledby="title"
      aria-describedby="description"
      open={props.open}
      className={classes.modal}
      onClose={handleClose}
    >
      <div className={classes.paper}>
        <div className={classes.header}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                margin: 0,
                width: "100%",
              }}
            >
              <h3 id="title">Verificar ticket: {props.ticket.CaseNumber}</h3>
            </Box>
            <h4 style={{ margin: "5px 0" }}>Líneas</h4>
            <ul style={{ height: "auto", margin: "0 0 20px 0" }}>
              {lineas &&
                lineas.map((linea) => (
                  <li style={{ marginBottom: "6px" }}>
                    <Grid container>
                      <Grid item xs={12}>
                        <p
                          style={{
                            fontSize: "12px",
                            textAlign: "left",
                            color: "grey",
                            margin: "0 0 10px 0",
                          }}
                        >
                          <strong>{linea.CB_Nombre_Producto__c}</strong>
                        </p>
                      </Grid>
                      <Grid item xs={4}>
                        <p
                          style={{
                            fontSize: "12px",
                            textAlign: "left",
                            color: "grey",
                          }}
                        >
                          Cantidad: <strong>{linea.CB_cantidad__c}</strong>
                        </p>
                      </Grid>
                      <Grid item xs={4}>
                        <p
                          style={{
                            fontSize: "12px",
                            textAlign: "left",
                            color: "grey",
                          }}
                        >
                          P. aptas: <strong>{linea.CB_Piezas_aptas__c}</strong>
                        </p>
                      </Grid>
                      <Grid item xs={4}>
                        <p
                          style={{
                            fontSize: "12px",
                            textAlign: "left",
                            color: "grey",
                          }}
                        >
                          P. no aptas:{" "}
                          <strong>{linea.CB_Piezas_no_aptas__c}</strong>
                        </p>
                      </Grid>
                      <Grid item xs={4}>
                        <p
                          style={{
                            fontSize: "12px",
                            textAlign: "left",
                            color: "grey",
                          }}
                        >
                          Recibida:{" "}
                          <TextField
                            size="small"
                            type={"number"}
                            variant="outlined"
                            margin="0 5px"
                            defaultValue={linea.CB_Cantidad_revisada__c ?? 0}
                            onChange={(e) => {
                              setLineas(
                                lineas.map((l) =>
                                  l.Id === linea.Id
                                    ? {
                                        ...l,
                                        CB_Cantidad_revisada__c: Number(
                                          e.target.value
                                        ),
                                      }
                                    : l
                                )
                              );
                            }}
                          />
                        </p>
                      </Grid>
                      <Grid item xs={4}>
                        <p
                          style={{
                            fontSize: "12px",
                            textAlign: "left",
                            color: "grey",
                          }}
                        >
                          Recibida:{" "}
                          <TextField
                            size="small"
                            type={"number"}
                            variant="outlined"
                            margin="0 5px"
                            defaultValue={
                              linea.CB_Piezas_aptas_recibidas__c ?? 0
                            }
                            onChange={(e) => {
                              setLineas(
                                lineas.map((l) =>
                                  l.Id === linea.Id
                                    ? {
                                        ...l,
                                        CB_Piezas_aptas_recibidas__c: Number(
                                          e.target.value
                                        ),
                                      }
                                    : l
                                )
                              );
                            }}
                          />
                        </p>
                      </Grid>
                      <Grid item xs={4}>
                        <p
                          style={{
                            fontSize: "12px",
                            textAlign: "left",
                            color: "grey",
                          }}
                        >
                          Recibida:{" "}
                          <TextField
                            size="small"
                            type={"number"}
                            variant="outlined"
                            margin="0 5px"
                            defaultValue={
                              linea.CB_Piezas_no_aptas_recibidas__c ?? 0
                            }
                            onChange={(e) => {
                              setLineas(
                                lineas.map((l) =>
                                  l.Id === linea.Id
                                    ? {
                                        ...l,
                                        CB_Piezas_no_aptas_recibidas__c: Number(
                                          e.target.value
                                        ),
                                      }
                                    : l
                                )
                              );
                            }}
                          />
                        </p>
                      </Grid>
                    </Grid>
                  </li>
                ))}
            </ul>
            <TextField
              style={{ width: "100%" }}
              id="comentario"
              label="Comentario Almacén"
              multiline
              minrows={4}
              value={comentarioAlmacen}
              onChange={(e) => setComentarioAlmacen(e.target.value)}
              variant="outlined"
            />
            <div style={{ marginTop: "40px", width: "100%" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
                  <div style={{ maxWidth: "100%", height: "auto" }}>
                    <Camera
                      onTakePhoto={(pic) => {
                        setFoto(pic);
                      }}
                      errorMessages={{
                        noCameraAccessible:
                          "No se puede acceder a la cámara o no se encuentra.",
                        permissionDenied:
                          "Permiso denegado. Recarga y acepta los permisos.",
                      }}
                      idealFacingMode={FACING_MODES.ENVIRONMENT}
                      idealResolution={{ width: 640, height: 480 }}
                      sizeFactor={1}
                    />
                  </div>
                </Grid>
                {foto ? (
                  <Grid item xs={12} sm={4}>
                    <div style={{ maxWidth: "100%", height: "auto" }}>
                      <img
                        src={foto}
                        style={{ maxWidth: "100%", height: "auto" }}
                        alt="foto"
                      />
                    </div>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Button
                          style={{
                            backgroundColor: "#00D903",
                            color: "white",
                            width: "100%",
                          }}
                          startIcon={<FileUploadIcon sx={{ mr: 1 }} />}
                          onClick={() => handleAdjuntar()}
                        >
                          Adjuntar
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          style={{
                            backgroundColor: "#E90000",
                            color: "white",
                            width: "100%",
                          }}
                          startIcon={<DeleteIcon sx={{ mr: 1 }} />}
                          onClick={() => setFoto("")}
                        >
                          Borrar
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            </div>
            <Button
              variant="contained"
              onClick={handleVerificar}
              style={{
                backgroundColor: "#117278",
                color: "white",
                marginTop: "40px",
                padding: "20px 0 20px 0",
                width: "180px",
              }}
              startIcon={<CheckCircleIcon sx={{ mr: 1 }} />}
            >
              Verificar
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalVerificar;
