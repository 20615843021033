import React, { useState, useEffect} from 'react';
import './pagos.scss';
import '../../config';
import Moment from 'moment';
import PagoTarjeta from './partes/pagoTarjeta'
import { getPresupuesto } from '../../services/presupuesto'
import DetallePresupuesto from '../../components/detalle/detallePresupuesto'
import {obtenerToken, obtenerCliente } from '../../services/tickets';
import {useParams} from 'react-router-dom'



const Pagos = (props) => {

    const [pagado, setPagado] = useState(false);
    const [presupuesto, setPresupuesto] = useState(null);
    const [cliente, setCliente] = useState(null);
    const params = useParams()


    useEffect( ()  => {
       
        async function getValues() {
            const dev = await getPresupuesto(params.id)

            if(global.fecha_token==''){
                global.token = await obtenerToken()
                global.fecha_token = new Date().toLocaleDateString()
            }
     
            const cli = await obtenerCliente(dev.presupuesto.idCliente.trim())

            setPresupuesto(dev)
            setPagado(dev.presupuesto.pagado)
            setCliente(cli)
        }
        getValues();
    }, [])


    return(
        presupuesto==null ? <div></div> : 
        pagado ? <div><h1>Este presupuesto ya esta pagado!</h1></div> : 
        <div className="detalle">
            {cliente ? <DetallePresupuesto  presupuesto={presupuesto} cliente={cliente}/> : <div></div>}
            
        </div>
    )

}

export default Pagos;
