import { Modal } from "@material-ui/core";
import EtiquetaPage from "../../pages/etiqueta/etiquetaPage";
import Test from "../qrcode/reader";
import "./modal.scss";

const ModalQR = (props) => {
  return (
    <Modal
      aria-labelledby="title"
      aria-describedby="description"
      open={props.state}
      className="modal"
      onClose={props.close}
    >
      {props.reader ? (
        <Test
          setState={props.setTicket}
          cerrar={props.close}
          tickets={props.tickets}
        />
      ) : (
        <EtiquetaPage
          ticket={props.ticket}
          cliente={props.cliente}
          idSalesforce={props.idSalesforce}
        />
      )}
    </Modal>
  );
};

export default ModalQR;
