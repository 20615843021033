import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MobileStepper from '@mui/material/MobileStepper';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from "react";
import "../../config";
import { getPruebas, setPruebas } from '../../services/reparacion';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100vh",
    margin: "0 auto",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #2a8daa",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "10px",
    "&:focus": {
      outline: "none",
    },
    width: "100vh",
  },
  line: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    verticalAlign: "middle",
    marginTop: '50px'
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
    color: "grey",
  },
  table: {
    display: "flex",
    justifyContent: "center",
    minHeight: "100px",
    padding: "10px",
  },
}));


const Pruebas = (props) => {
  const classes = useStyles();

  const [filas, setFilas] = useState(props.reparacion ? props.reparacion.pruebas : []);
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSend = async (valor) => {

    const pres = await setPruebas(props.ticket, filas[activeStep].idError, valor)
    const prub = await getPruebas(props.ticket, props.tipoProducto)
    setFilas(prub)
  
  }

  useEffect(() => {
    async function fetchData() {
      const dev = await getPruebas(props.ticket, props.tipoProducto)
      setFilas(dev)
  }
  
  fetchData();
  }, [props.tipoProducto])

  return (
    filas.length>0 ? <>
      <div className={classes.line}>
      <Box sx={{ maxWidth: 500, flexGrow: 1 }}>
      <Box sx={{marginBottom: '20px', textAlign: 'center', fontSize: '18px', maxWidth: 500, width: '100%'}}>
        {filas[activeStep].grupo}
      </Box>
      <Box sx={{marginBottom: '20px', textAlign: 'center', fontSize: '18px',  maxWidth: 500, width: '100%', color: '#2a8daa'}}>
        {filas[activeStep].descripcion}
      </Box>
      <Box sx={{marginBottom: '50px', textAlign: 'center',  maxWidth: 500, width: '100%', color: '#2a8daa'}}>
        {filas[activeStep].valor === 1 ? <p style={{color: 'green'}}>¡Pasada!</p> : filas[activeStep].valor === 2 ?  <p style={{color: 'red'}}>Errónea...</p> : ""}
      </Box>
      <Box className={classes.line} sx={{marginBottom: '30px', textAlign: 'center', maxWidth: 500, width: '100%'}}>
      <button
          type="button"
          className="blue_button"
          onClick={() => handleSend(1)}
        >
          Funciona
        </button>
        <button
          type="button"
          className="red_button"
          onClick={() => handleSend(2)}
        >
          Error
        </button>
      </Box>
      <MobileStepper
        className={classes.line}
        variant="text"
        steps={filas.length}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === filas.length - 1}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
      </div>
      
    </> : <div></div>
  );
          }
export default Pruebas;
