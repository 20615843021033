import { Fade, Modal, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@mui/material";
import CryptoJS from "crypto-js";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "../../config";
import {
  addLineasPresupuesto,
  addPresupuesto,
  generarPdf,
} from "../../services/presupuesto";
import { getProductos } from "../../services/producto";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #2a8daa",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "10px",
    "&:focus": {
      outline: "none",
    },
  },
  line: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  table: {
    display: "flex",
    justifyContent: "center",
    minHeight: "100px",
    padding: "10px",
  },
}));

const textos = {
  1: {
    esp: "Reparación motor depuradora cartucho",
    port: "Reparação motor bomba cartucho",
  },
  2: {
    esp: "Reparación motor depuradora spa",
    port: "Reparação motor bomba spa",
  },
  3: {
    esp: "Reparación sistema de calefacción spa",
    port: "Reparação sistema de aquecimento spa",
  },
  4: {
    esp: "Reparación sistema de burbujas spa",
    port: "Reparação sistema de borbulhas spa",
  },
  5: {
    esp: "Repación placa de control spa",
    port: "Reparação placa de controlo spa",
  },
  6: {
    esp: "Reparación placa de control clorador salino",
    port: "Reparação placa de controlo clorador salino",
  },
  7: {
    esp: "Reparación conjunto sistema jet spa",
    port: "Reparação conjunto sistema jet spa",
  },
  8: {
    esp: "Fuga de agua en motor",
    port: "Fuga do agua en motor",
  },
  9: {
    esp: "Rotura de piezas",
    port: "Rotura do peças",
  },
  10: {
    esp: "Pieza de repuesto",
    port: "Peça do repuesto",
  },
  11: {
    esp: "Revisión y puesta en marcha",
    port: "Revisão e funcionamento",
  },
};

const ModalForm = (props) => {
  const classes = useStyles();
  const [filas, setFilas] = useState([]);
  const [products, setProducts] = useState([]);
  const [obs, setObs] = useState("");
  const { register, errors, handleSubmit } = useForm();
  const [product, setProduct] = useState({ product: null, price: null });

  const handleChange = async (data, e) => {
    let news = [...filas];
    //const prod = await obtenerProducto(data.Producto)
    news.push({
      idProducto: data.tipo,
      idLinea: filas.length + 1,
      descripcion:
        props.pais?.toLowerCase() === "españa"
          ? textos[data.tipo]["esp"]
          : textos[data.tipo]["port"], //prod.DESC60.length>40 ? prod.DESC60.substring(0,40) : prod.DESC60,
      cantidad: 1, //data.Cantidad,
      precio: data.precio,
      fecha: new Date().toLocaleDateString("es-ES"),
    });
    setFilas(news);
    e.target.reset();
  };

  const handleChangeProductos = async () => {
    let news = [...filas];
    news.push({
      idProducto: product.product.id,
      idLinea: filas.length + 1,
      descripcion: product.product.nombre, 
      cantidad: 1, 
      precio: product.price,
      fecha: new Date().toLocaleDateString("es-ES"),
    });
    setFilas(news);
    setProduct({ product: null, price: null });
  };

  const handleChangeObs = async (e) => {
    setObs(e.target.value);
  };

  const handleClose = () => {
    setFilas([]);
    props.close();
  };

  const handleSend = async () => {
    var hash = CryptoJS.SHA3(props.ticket, { outputLength: 224 });
    var totpre = 0.0;

    filas.map((linea) => {
      totpre += linea.precio * linea.cantidad;
    });

    var presupuesto = {
      idPresupuesto: props.ticket,
      idTicket: props.idSales,
      idCliente: props.cliente,
      observaciones: obs,
      total: totpre,
      pagado: 0,
      hash: hash.toString(),
      fechaCreacion: new Date().toLocaleDateString("es-ES"),
      fechaPago: new Date().toLocaleDateString("es-ES"),
      token: global.token,
      idSales: props.idSales,
    };

    const pres = await addPresupuesto(presupuesto);
    if (pres === 200) {
      console.log(filas)
      await addLineasPresupuesto(props.ticket, filas);
    }

    generarPdf(hash.toString(), props.cliente, props.pais);

    handleClose();
  };

  const lista = filas.map((fila) => {
    return (
      <tr>
        <td>{fila.descripcion}</td>
        <td>{fila.precio}</td>
      </tr>
    );
  });

  useEffect(() => {
    props.state &&
      getProductos().then((ps) =>
        setProducts(
          ps.productos.reduce((prodsMap, prod) => {
            prodsMap = [
              ...prodsMap,
              {
                label:
                  prod.referencia +
                  " - " +
                  prod.idProducto +
                  " - " +
                  prod.nombre,
                id: prod.idProducto,
                ref: prod.referencia,
                nombre: prod.nombre,
              },
            ];
            return prodsMap;
          }, [])
        )
      );
  }, [props.state]);

  return (
    <Modal
      aria-labelledby="title"
      aria-describedby="description"
      open={props.state}
      className={classes.modal}
      onClose={handleClose}
    >
      <Fade in={props.state}>
        <div className={classes.paper}>
          <div className={classes.header}>
            <h3 id="title">Presupuesto para el ticket: {props.ticket}</h3>
            <button
              type="submit"
              class="yellow_button"
              style={{ margin: "10px" }}
              onClick={handleSend}
            >
              Crear Presupuesto
            </button>
          </div>

          <hr color="#2a8daa" />
          <div className={classes.table}>
            <table width="100%">
              <tr style={{ color: "#2a8daa" }}>
                <td>Descripción</td>
                <td>Precio</td>
              </tr>
              {filas.length === 0 ? (
                <tr>
                  <td colSpan="4" style={{ color: "#C4C4C4" }}>
                    No hay ninguna linea de presupuesto
                  </td>
                </tr>
              ) : (
                lista
              )}
            </table>
          </div>
          <hr color="#2a8daa" />
          <form
            className={classes.root}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(handleChange)}
          >
            <div key={props.id} className={classes.line}>
              <select
                style={{
                  padding: "10px",
                  margin: "10px",
                  width: "400px",
                  fontSize: "14px",
                }}
                variant="outlined"
                id="tipo"
                name="tipo"
                {...register("tipo", {
                  required: { value: true, message: "Ingrese un nombre" },
                })}
              >
                <option value="0">Selecccion una opción...</option>
                <option value="1">Reparación motor depuradora cartucho</option>
                <option value="2">Reparación motor depuradora spa</option>
                <option value="3">Reparación sistema de calefacción spa</option>
                <option value="4">Reparación sistema de burbujas spa</option>
                <option value="5">Repación placa de control spa</option>
                <option value="6">
                  Reparación placa de control clorador salino
                </option>
                <option value="7">Reparación conjunto sistema jet spa</option>
                <option value="8">Fuga de agua en motor</option>
                <option value="9">Rotura de piezas</option>
                <option value="10">Pieza de repuesto</option>
                <option value="11">Revisión y puesta en marcha</option>
              </select>
              <input
                id="precio"
                placeholder="Precio"
                name="precio"
                variant="outlined"
                {...register("precio", {
                  required: { value: true, message: "Ingrese un nombre" },
                })}
                style={{ padding: "10px", margin: "10px" }}
              />
              <button
                type="submit"
                className="blue_button"
                style={{ margin: "10px" }}
              >
                + Añadir Línea
              </button>
            </div>
          </form>
          <hr color="#2a8daa" />
          <form
            className={classes.root}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(handleChangeProductos)}
          >
            <div key={props.id} className={classes.line}>
              <Autocomplete
                className="textField"
                id="producto"
                style={{
                  padding: "10px",
                  margin: "10px",
                  width: "400px",
                  fontSize: "14px",
                }}
                variant="outlined"
                options={products ?? []}
                value={product.product}
                getOptionLabel={(option) => option?.label}
                name="producto"
                onChange={(event) => {
                  setProduct({
                    ...product,
                    product: products.find(
                      (p) => p.label === event.target.textContent
                    ),
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Producto"
                    placeholder={product.product?.label ?? ""}
                  />
                )}
              />
              <input
                id="precio"
                placeholder="Precio"
                name="precio"
                variant="outlined"
                type="number"
                onChange={(e) =>
                  setProduct({ ...product, price: e.target.value })
                }
                value={product.price ?? ""}
                style={{ padding: "10px", margin: "10px" }}
              />
              <button
                type="button"
                onClick={handleChangeProductos}
                className="blue_button"
                style={{ margin: "10px" }}
              >
                + Añadir Línea
              </button>
            </div>
          </form>
          <hr color="#2a8daa" />
          <textarea
            id="comentariosTaller"
            placeholder="Comentarios Taller"
            name="comentariosTaller"
            variant="outlined"
            style={{ padding: "10px", margin: "10px", width: "94%" }}
            rows="4"
            onChange={handleChangeObs}
          />
        </div>
      </Fade>
    </Modal>
  );
};

export default ModalForm;
