import { Grid, List, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import PrintIcon from "@mui/icons-material/Print";
import MarkunreadMailboxTwoToneIcon from "@mui/icons-material/MarkunreadMailboxTwoTone";
import Fab from "@mui/material/Fab";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import logo from "../../images/objetivo.svg";
import {
  getProducts,
  obtenerLineas,
  obtenerTicket,
} from "../../services/tickets";
import ModalForm from "../modal/modal";
import ModalQR from "../modal/modalQR";
import ModalVerificar from "../modal/modalVerificar";
import ModalFormDetalle from "../modal/modal_detalle";
import "./detalle.scss";
import { createPDF } from "../print/print";
import {
  getDefectTypes,
  getDetailErrors,
  getReceivedProducts,
  getReparacion,
} from "../../services/reparacion";
import { getProductos } from "../../services/producto";

const useStyles = makeStyles((theme) => ({
  item: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    margin: "10px",
  },
}));

const DetalleTicket = (props) => {
  const classes = useStyles();
  const [detalle, setDetalle] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModalDetalle, setShowModalDetalle] = useState(false);
  const [showVerificarModal, setShowVerificarModal] = useState(false);
  const [lineas, setLineas] = useState(null);
  const [pieces, setPieces] = useState([]);
  const [defectTypes, setDefectsTypes] = useState([]);
  const [errorDetails, setErrorDetails] = useState([]);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (props.state) {
      obtenerTicket(props.state).then((res) => {
        setDetalle(res);
        if (localStorage.getItem("idSeccion") !== "1") {
          obtenerLineas(res.CB_Pedido_Recogida__c).then((resp) => {
            setLineas(resp.records ?? []);
          });
        } else {
          setLineas([]);
        }
      });
    }
    pieces.length === 0 && getReceivedProducts().then((res) => setPieces(res));
    errorDetails.length === 0 &&
      getDetailErrors().then((res) => setErrorDetails(res));
    defectTypes.length === 0 &&
      getDefectTypes().then((res) => setDefectsTypes(res));
    products.length === 0 &&
      getProductos().then((res) => setProducts(res.productos));
  }, [props.state, props.loading, showVerificarModal]);

  const handleOpen = () => {
    setShowModal(true);
  };

  const handleOpenQR = () => {
    setShowModal2(true);
  };

  const handleOpenDetalle = () => {
    setShowModalDetalle(true);
  };

  const handleOpenVerificar = () => {
    setShowVerificarModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setShowModal2(false);
    setShowModalDetalle(false);
    setShowVerificarModal(false);
  };

  const mapPieces = (pcs) => {
    if (pcs !== null && pcs) {
      var piecesMapped = Object.keys(pcs).map((key) => ({
        piece: key,
        value: pcs[key],
      }));
      piecesMapped = piecesMapped.filter((p) => p.value === 1);
      piecesMapped = pieces.filter((p) =>
        piecesMapped.find((pm) => pm.piece === p.name)
      );
      piecesMapped = [...new Map(piecesMapped.map((m) => [m.id, m])).values()];
      return piecesMapped;
    } else {
      return [];
    }
  };

  const mapProduct = (id) => {
    const prod = products.find((p) => p.idProducto === id) || null;
    return prod ? prod?.referencia + " - " + prod?.nombre  : "";
  };

  const mapErrors = (errs) => {
    if (errs !== null && errs) {
      var errorsMapped = Object.keys(errs).map((key) => ({
        error: key,
        value: errs[key],
      }));
      var observations = errorsMapped.find((em) => em.error === "comentarios");
      errorsMapped = errorsMapped.filter((p) => p.value === 1);
      errorsMapped = defectTypes.filter((p) =>
        errorsMapped.find((pm) => pm.error === p.name)
      );
      errorsMapped = [...new Map(errorsMapped.map((m) => [m.id, m])).values()];
      return { observations: observations?.value, defects: errorsMapped };
    } else {
      return { observations: "", defects: [] };
    }
  };

  const mapProofs = (proofs) => {
    if (proofs !== null && proofs) {
      var proofsMapped = proofs.filter((p) => p.valor === 2);
      proofsMapped = errorDetails.filter((ed) =>
        proofsMapped.some((pm) => ed.idError === pm.idError)
      );
      return proofsMapped;
    } else {
      return [];
    }
  };

  const printPDF = async () => {
    var product = null;
    var piecesSelected = [];
    var usedPieces = [];
    var proofs = [];
    var defects = { observations: "", defects: [] };
    var data = null;

    await getProducts(detalle.CB_Producto__c).then(
      (prod) => (product = prod.records[0])
    );
    await getReparacion(detalle.CaseNumber).then((res) => {
      data = res.datos;
      piecesSelected = mapPieces(res.piezas);
      usedPieces = res.lineas;
      defects = mapErrors(res.errores);
      proofs = mapProofs(res.pruebas);
      data = {
        ...data,
        received_product: mapProduct(res.datos?.idReceivedProduct ?? null),
      };
    });

    createPDF(
      detalle,
      product,
      piecesSelected,
      data,
      usedPieces,
      defects,
      proofs
    );
  };

  return (
    <div
      className="detail-content"
      style={{ padding: isMobile ? 0 : "40px 20px 0 20px" }}
    >
      {props.state === null && (
        <div className="no-select">
          <img src={logo} id="logo" alt="logo" />
          <br />
          <p>No ha seleccionado ninguna!</p>
        </div>
      )}
      {detalle !== null && (
        <div>
          <div id="detalle_cab">
            <span>
              Detalle del ticket: <strong>{detalle.CaseNumber}</strong>
            </span>
            {localStorage.getItem("idSeccion") === "1" ? (
              <div>
                <button className="blue_button" onClick={handleOpenQR}>
                  Generar etiqueta
                </button>
                <button className="blue_button" onClick={handleOpen}>
                  Generar Presupuesto
                </button>
                <Fab
                  size="medium"
                  variant="extended"
                  onClick={handleOpenDetalle}
                  style={{
                    backgroundColor: "#117278",
                    color: "white",
                    marginRight: "5px",
                  }}
                >
                  <EditIcon sx={{ mr: 1 }} />
                  Editar
                </Fab>
                <Fab
                  size="medium"
                  variant="extended"
                  onClick={async () => await printPDF(detalle)}
                  style={{ backgroundColor: "#117278", color: "white" }}
                >
                  <PrintIcon />
                </Fab>
              </div>
            ) : (
              <div>
                {detalle.CB_Estado_del_ticket__c === "Pdte Recibir Pieza" ? (
                  <Fab
                    size="medium"
                    variant="extended"
                    onClick={async () =>
                      await props
                        .cambiarEstado({
                          id: detalle?.CB_Ticket_Interno__c,
                          estado: "Recibido - Pdte Verificar",
                        })
                        .then(() => {
                          setDetalle({
                            ...detalle,
                            CB_Estado_del_ticket__c:
                              "Recibido - Pdte Verificar",
                          });
                        })
                    }
                    style={{
                      backgroundColor: "#00A0C0",
                      color: "white",
                      margin: "5px",
                    }}
                  >
                    <MarkunreadMailboxTwoToneIcon sx={{ mr: 1 }} />
                    Recibido
                  </Fab>
                ) : null}
                {detalle.CB_Estado_del_ticket__c !== "Verificado" ? (
                  <Fab
                    size="medium"
                    variant="extended"
                    onClick={handleOpenVerificar}
                    style={{
                      backgroundColor: "#1EB200",
                      color: "white",
                      margin: "5px",
                    }}
                  >
                    <CheckCircleIcon sx={{ mr: 1 }} />
                    Verificar
                  </Fab>
                ) : null}
              </div>
            )}
          </div>

          <hr color="#2a8daa" />
          <List
            style={{
              maxHeight:
                localStorage.getItem("idSeccion") === "1" ? "68vh" : "78vh",
              overflow: "overlay",
            }}
          >
            <Grid container style={{ flexGrow: 1 }} spacing={1}>
              <Grid item xs={12}>
                <Paper className={classes.item}>
                  <h3 className="titleSection">{detalle.Subject}</h3>
                  <p style={{ fontSize: "14px", wordBreak: "break-all" }}>
                    {detalle.Description}
                  </p>
                </Paper>
              </Grid>
              {localStorage.getItem("idSeccion") !== "1" && (
                <Grid item xs={12}>
                  <Paper className={classes.item}>
                    <h5 className="titleSection">Líneas</h5>
                    <ul style={{ height: "auto" }}>
                      {lineas
                        ? lineas?.map((linea) => (
                            <li>
                              <strong>{linea.CB_Referencia_Producto__c}</strong>
                              <br></br>
                              Aptas:{" "}
                              <strong>
                                {linea.CB_Piezas_aptas__c ?? " 0"}
                              </strong>{" "}
                              {"   "}
                              No aptas:{" "}
                              <strong>
                                {linea.CB_Piezas_no_aptas__c ?? " 0"}
                              </strong>
                            </li>
                          ))
                        : "No hay líneas"}
                    </ul>
                  </Paper>
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <Paper className={classes.item}>
                  <h5 className="titleSection">Nombre Cliente</h5>
                  <span>{detalle.SuppliedName}</span>
                </Paper>
                <Paper className={classes.item}>
                  <h5 className="titleSection">Dirección</h5>
                  <span>{detalle.CB_Direccion_entrega__c}</span>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper className={classes.item}>
                  <h5 className="titleSection">Ciudad</h5>
                  <span>{detalle.CB_Ciudad__c}</span>
                </Paper>
                <Paper className={classes.item}>
                  <h5 className="titleSection">Provincia</h5>
                  <span>{detalle.CB_Provincia__c}</span>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper className={classes.item}>
                  <h5 className="titleSection">Estado del ticket</h5>
                  <span>{detalle.CB_Estado_del_ticket__c}</span>
                </Paper>
                <Paper className={classes.item}>
                  <h5 className="titleSection">Tipo de ticket</h5>
                  <span>{detalle.CB_Tipo_de_ticket__c}</span>
                </Paper>
                <Paper className={classes.item}>
                  <h5 className="titleSection">Subtipo de ticket</h5>
                  <span>{detalle.CB_Subtipo_de_ticket__c}</span>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper className={classes.item}>
                  <h5 className="titleSection">Fecha de compra</h5>
                  <span>
                    {detalle.Fecha_de_compra__c
                      ? detalle.Fecha_de_compra__c
                      : "No definido"}
                  </span>
                </Paper>
                <Paper className={classes.item}>
                  <h5 className="titleSection">Origen del pedido</h5>
                  <span>{detalle.CB_Lugar_de_compra__c}</span>
                </Paper>
                <Paper className={classes.item}>
                  <h5 className="titleSection">Gestión a realizar</h5>
                  <span>{detalle.CB_Gestion_del_ticket__c}</span>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper className={classes.item}>
                  <h5 className="titleSection">Comentarios Internos</h5>
                  <span>
                    {detalle.CB_Comentario_Interno__c
                      ? detalle.CB_Comentario_Interno__c
                      : "No se ha añadido ningún comentario interno."}
                  </span>
                </Paper>
              </Grid>
              {localStorage.getItem("idSeccion") === "1" ? (
                <Grid item xs={12}>
                  <Paper className={classes.item}>
                    <h5 className="titleSection">Comentario Taller</h5>
                    <span>
                      {detalle.CB_Comentario_Taller__c
                        ? detalle.CB_Comentario_Taller__c
                        : "No se ha añadido ningún comentario interno."}
                    </span>
                  </Paper>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Paper className={classes.item}>
                    <h5 className="titleSection">Comentario Almacén</h5>
                    <span>
                      {detalle.CB_Comentario_Almacen__c
                        ? detalle.CB_Comentario_Almacen__c
                        : "No se ha añadido ningún comentario interno."}
                    </span>
                  </Paper>
                </Grid>
              )}
            </Grid>
          </List>
          <ModalForm
            state={showModal}
            open={handleOpen}
            close={handleClose}
            ticket={detalle.CaseNumber}
            cliente={detalle.AccountId}
            pais={detalle.CB_pais__c}
            idSales={detalle.Id}
          />
          <ModalQR
            state={showModal2}
            reader={false}
            close={handleClose}
            ticket={detalle.CaseNumber}
            cliente={detalle.AccountId}
            idSalesforce={detalle.Id}
          />
          <ModalFormDetalle
            state={showModalDetalle}
            ticket={detalle}
            close={handleClose}
          />
          <ModalVerificar
            open={showVerificarModal}
            ticket={detalle}
            lineas={lineas ?? []}
            close={handleClose}
          />
        </div>
      )}
    </div>
  );
};

export default DetalleTicket;
