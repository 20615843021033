import React, { useState, useEffect } from "react";
import "./detalle.scss";
import {
  TextField,
  InputAdornment,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import {
  AccountCircle,
  SupervisorAccountRounded,
  AlternateEmailRounded,
  Phone,
  HomeRounded,
  Description,
  PublicRounded,
  EmailRounded,
  CreditCardRounded,
  LocationCityRounded,
} from "@material-ui/icons";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { pagarPresupuesto } from "../../services/pago";
import ReactHtmlParser from "react-html-parser";

const useStyles = makeStyles((theme) => ({
  table: {
    display: "flex",
    justifyContent: "center",
    minHeight: "100px",
    padding: "10px",
    color: "grey",
  },
}));

const DetallePresupuesto = (props) => {
  const { register, errors, handleSubmit } = useForm();

  const [formPago, setForm] = useState();
  const classes = useStyles();
  const handleSend = async () => {
    setForm(
      await pagarPresupuesto({
        total: (props.presupuesto.presupuesto.total * 100).toString(),
        ticket: props.presupuesto.presupuesto.idPresupuesto.toString(),
        hash: props.presupuesto.presupuesto.hash.toString(),
      })
    );
  };

  const lista = props.presupuesto.lineas.map((fila) => {
    return (
      <tr key={fila.idProducto}>
        <td style={{ padding: "10px" }}>{fila.idProducto}</td>
        <td style={{ padding: "10px" }}>{fila.descripcion}</td>
        <td style={{ padding: "10px" }}></td>
      </tr>
    );
  });

  return (
    <div className="detail-content">
      <h3>
        Presupuesto del ticket: {props.presupuesto.presupuesto.idPresupuesto}
      </h3>

      {formPago ? (
        ReactHtmlParser(formPago)
      ) : (
        <form noValidate autoComplete="off" onSubmit={handleSubmit(handleSend)}>
          <div className="cuadroPresupueso">
            <TextField
              id="nombre"
              placeholder="Nombre"
              name="nombre"
              variant="outlined"
              value={props.cliente.FirstName}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
              {...register("nombre", {
                required: { value: true, message: "Ingrese un nombre" },
              })}
              style={{ padding: "15px" }}
            />
            <TextField
              id="apellidos"
              placeholder="Apellidos"
              name="apellidos"
              variant="outlined"
              value={props.cliente.LastName}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SupervisorAccountRounded />
                  </InputAdornment>
                ),
              }}
              {...register("apellidos", {
                required: { value: true, message: "Ingrese un nombre" },
              })}
              style={{ padding: "15px" }}
            />
            <TextField
              id="email"
              placeholder="Email"
              name="email"
              variant="outlined"
              value={props.cliente.PersonEmail}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AlternateEmailRounded />
                  </InputAdornment>
                ),
              }}
              {...register("email", {
                required: { value: true, message: "Ingrese un nombre" },
              })}
              style={{ padding: "15px" }}
            />
            <TextField
              id="direccion"
              placeholder="Dirección"
              name="direccion"
              variant="outlined"
              value={props.cliente.ShippingStreet}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <HomeRounded />
                  </InputAdornment>
                ),
              }}
              {...register("direccion", {
                required: { value: true, message: "Ingrese un nombre" },
              })}
              style={{ padding: "15px" }}
            />
            <TextField
              id="codpos"
              placeholder="Código Postal"
              name="codpos"
              variant="outlined"
              value={props.cliente.ShippingPostalCode}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailRounded />
                  </InputAdornment>
                ),
              }}
              {...register("codpos", {
                required: { value: true, message: "Ingrese un nombre" },
              })}
              style={{ padding: "15px" }}
            />
            <TextField
              id="ciudad"
              placeholder="Ciudad"
              name="ciudad"
              variant="outlined"
              value={props.cliente.ShippingCity}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocationCityRounded />
                  </InputAdornment>
                ),
              }}
              {...register("ciudad", {
                required: { value: true, message: "Ingrese un nombre" },
              })}
              style={{ padding: "15px" }}
            />
            <TextField
              id="provincia"
              placeholder="Provincia"
              name="provincia"
              variant="outlined"
              value={props.cliente.ShippingState}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PublicRounded />
                  </InputAdornment>
                ),
              }}
              {...register("provincia", {
                required: { value: true, message: "Ingrese un nombre" },
              })}
              style={{ padding: "15px" }}
            />
            <RadioGroup
              aria-label="País"
              name="pais"
              value={
                props.cliente.ShippingCountry?.toLowerCase().includes("españa")
                  ? "españa"
                  : "portugal"
              }
              style={{
                width: "28%",
                margin: "0px",
                flexWrap: "nowrap",
                flexDirection: "row",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "25px",
              }}
            >
              <FormControlLabel
                value="españa"
                control={<Radio />}
                label="España"
              />
              <FormControlLabel
                value="portugal"
                control={<Radio />}
                label="Portugal"
              />
            </RadioGroup>
            <TextField
              id="dni"
              placeholder="Dni"
              name="dni"
              variant="outlined"
              value={props.cliente.CB_NIF__c}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CreditCardRounded />
                  </InputAdornment>
                ),
              }}
              {...register("dni", {
                required: { value: true, message: "Ingrese un nombre" },
              })}
              style={{ padding: "15px" }}
            />
            <TextField
              id="telefono"
              placeholder="Teléfono"
              name="telefono"
              variant="outlined"
              value={props.cliente.PersonMobilePhone}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Phone />
                  </InputAdornment>
                ),
              }}
              {...register("telefono", {
                required: { value: true, message: "Ingrese un nombre" },
              })}
              style={{ padding: "15px" }}
            />
            <TextField
              id="observaciones"
              placeholder="Observaciones"
              name="observaciones"
              variant="outlined"
              value={props.cliente.Description}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Description />
                  </InputAdornment>
                ),
              }}
              style={{ padding: "15px" }}
            />
          </div>
          <br />
          <br />
          <div className="cuadroPresupueso">
            <div className={classes.table}>
              <table width="100%">
                <thead>
                  <tr style={{ color: "#2a8daa" }}>
                    <td
                      style={{
                        borderBottom: "1px solid #2a8daa",
                        paddingBottom: "10px",
                      }}
                    >
                      Referencia
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid #2a8daa",
                        paddingBottom: "10px",
                      }}
                    >
                      Descripción
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid #2a8daa",
                        paddingBottom: "10px",
                      }}
                    ></td>
                  </tr>
                </thead>
                <tbody>{lista}</tbody>
                <tfoot>
                  <tr style={{ color: "#2a8daa" }}>
                    <td
                      style={{
                        borderTop: "1px solid #2a8daa",
                        padding: "10px",
                      }}
                    ></td>
                    <td
                      style={{
                        borderTop: "1px solid #2a8daa",
                        padding: "10px",
                        textAlign: "right",
                      }}
                    >
                      Neto
                    </td>
                    <td
                      style={{
                        borderTop: "1px solid #2a8daa",
                        padding: "10px",
                      }}
                    >
                      {(props.presupuesto.presupuesto.total / 1.21).toFixed(2)}{" "}
                      €
                    </td>
                  </tr>
                  <tr style={{ color: "#2a8daa" }}>
                    <td
                      style={{
                        borderTop: "1px solid #2a8daa",
                        padding: "10px",
                      }}
                    ></td>
                    <td
                      style={{
                        borderTop: "1px solid #2a8daa",
                        padding: "10px",
                        textAlign: "right",
                      }}
                    >
                      IVA 21%
                    </td>
                    <td
                      style={{
                        borderTop: "1px solid #2a8daa",
                        padding: "10px",
                      }}
                    >
                      {(
                        props.presupuesto.presupuesto.total -
                        props.presupuesto.presupuesto.total / 1.21
                      ).toFixed(2)}{" "}
                      €
                    </td>
                  </tr>
                  <tr style={{ color: "#2a8daa" }}>
                    <td></td>
                    <td
                      style={{
                        borderTop: "1px solid #2a8daa",
                        padding: "10px",
                        textAlign: "right",
                      }}
                    >
                      Total
                    </td>
                    <td
                      style={{
                        borderTop: "1px solid #2a8daa",
                        padding: "10px",
                      }}
                    >
                      {props.presupuesto.presupuesto.total} €
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <br />
          <br />
          <button className="blue_button">PAGAR PRESUPUESTO</button>
        </form>
      )}
    </div>
  );
};

export default DetallePresupuesto;
