import React from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { obtenerTicket, obtenerTicketInterno } from "../../services/tickets";

const Test = (props) => {
  const handleChange = async (text) => {
    if (text) {
      if (localStorage.getItem("idSeccion") === "1") {
        var dev = await obtenerTicket(text);
        await props.setState(
          { idTicket: text },
          dev.CB_Estado_del_ticket__c === "Pdte Recibir Pieza"
        );
        props.cerrar();
      } else {
        const idTicket = props.tickets.find((ticket) =>
          ticket.CB_Descripcion__c?.toLowerCase().includes(
            text.replace(" ", "")
          )
        )?.Id;
        if (idTicket) {
          const dev = await obtenerTicket(idTicket);
          const interno = await obtenerTicketInterno(dev.CB_Ticket_Interno__c);
          await props.setState(
            {
              idInterno: dev.CB_Ticket_Interno__c,
              idTicket,
            },
            interno.Estado_del_ticket__c === "Pdte Recibir Pieza"
          );
        } else {
          const dev = await obtenerTicket(text);
          const interno = await obtenerTicketInterno(dev.CB_Ticket_Interno__c);
          await props.setState(
            {
              idInterno: dev.CB_Ticket_Interno__c,
              idTicket: text,
            },
            interno.Estado_del_ticket__c === "Pdte Recibir Pieza"
          );
        }
        props.cerrar();
      }
    }
  };

  return (
    <>
      <BarcodeScannerComponent
        height={500}
        width={500}
        torch={true}
        onUpdate={(err, result) => handleChange(result.text)}
      />
    </>
  );
};

export default Test;
